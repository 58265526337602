define("discourse/plugins/discourse-locations/discourse/initializers/location-edits", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/models/nav-item", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-locations/discourse/lib/location-utilities"], function (_exports, _runloop, _pluginApi, _composer, _navItem, _decorators, _I18n, _locationUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NEW_TOPIC_KEY = "new_topic";
  var _default = _exports.default = {
    name: "location-edits",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const site = container.lookup("site:main");
      (0, _pluginApi.withPluginApi)("0.8.23", api => {
        api.decorateWidget("post-body:after-meta-data", helper => {
          const model = helper.getModel();
          if (siteSettings.location_user_post && model.user_custom_fields && model.user_custom_fields["geo_location"]) {
            let format = siteSettings.location_user_post_format.split("|");
            let opts = {};
            if (format.length) {
              opts["geoAttrs"] = format;
            }
            let locationText = (0, _locationUtilities.geoLocationFormat)(model.user_custom_fields["geo_location"], site.country_codes, opts);
            return helper.h("div.user-location", locationText);
          }
        });
        api.modifyClass("controller:users", {
          pluginId: "locations-plugin",
          loadUsers(params) {
            if (params !== undefined && params.period === "location") {
              return;
            }
            this._super(params);
          }
        });
        api.modifyClass("model:composer", dt7948.p({
          pluginId: "locations-plugin",
          showLocationControls(subtype, categoryId, topicFirstPost, force) {
            if (!topicFirstPost) {
              return false;
            }
            if (force) {
              return true;
            }
            if (categoryId) {
              const category = this.site.categories.findBy("id", categoryId);
              if (category && category.custom_fields?.location_enabled) {
                return true;
              }
            }
            return false;
          },
          clearState() {
            this._super(...arguments);
            this.set("location", null);
          },
          _setupDefaultLocation() {
            if (this.draftKey.startsWith(NEW_TOPIC_KEY)) {
              const topicDefaultLocation = this.siteSettings.location_topic_default;
              // NB: we can't use the siteSettings, nor currentUser values set in the initialiser here
              // because in QUnit they will not be defined as the initialiser only runs once
              // so this will break all tests, even if in runtime it may work.
              // so solution is to use the values provided by the Composer model under 'this'.
              if (topicDefaultLocation === "user" && this.user.custom_fields.geo_location && (typeof this.user.custom_fields.geo_location === "string" && this.user.custom_fields.geo_location.replaceAll(" ", "") !== "{}" || typeof this.user.custom_fields.geo_location === "object" && Object.keys(this.user.custom_fields.geo_location).length !== 0)) {
                this.set("location", {
                  geo_location: this.user.custom_fields.geo_location
                });
              }
            }
          }
        }, [["method", "showLocationControls", [(0, _decorators.default)("subtype", "categoryId", "topicFirstPost", "forceLocationControls")]], ["method", "_setupDefaultLocation", [(0, _decorators.observes)("draftKey")]]]));
        api.modifyClass("component:composer-body", dt7948.p({
          pluginId: "locations-plugin",
          resizeWhenLocationAdded: function () {
            this._triggerComposerResized();
          },
          applyLocationInlineClass() {
            const applyClasses = () => {
              const showLocationControls = this.get("composer.showLocationControls");
              const containerElement = document.querySelector(".composer-fields .title-and-category");
              if (containerElement) {
                // Toggle the "show-location-controls" class based on `showLocationControls`
                if (showLocationControls) {
                  containerElement.classList.add("show-location-controls");
                } else {
                  containerElement.classList.remove("show-location-controls");
                }
                if (showLocationControls) {
                  const anchorElement = this.site.mobileView ? containerElement.querySelector(".title-input") : containerElement;

                  // Move ".composer-controls-location" element to `anchorElement`
                  const locationControl = document.querySelector(".composer-controls-location");
                  if (locationControl && anchorElement) {
                    anchorElement.appendChild(locationControl);
                  }
                }
                this._triggerComposerResized();
              }
            };
            (0, _runloop.scheduleOnce)("afterRender", this, applyClasses);
          }
        }, [["field", "resizeWhenLocationAdded", [(0, _decorators.observes)("composer.location")]], ["method", "applyLocationInlineClass", [(0, _decorators.observes)("composer.showLocationControls", "composer.composeState")]]]));
        const subtypeShowLocation = ["event", "question", "general"];
        api.modifyClass("model:topic", dt7948.p({
          pluginId: "locations-plugin",
          showLocationControls(subtype, categoryEnabled) {
            return subtypeShowLocation.indexOf(subtype) > -1 || categoryEnabled;
          }
        }, [["method", "showLocationControls", [(0, _decorators.default)("subtype", "category.custom_fields.location_enabled")]]]));

        // necessary because topic-title plugin outlet only recieves model
        api.modifyClass("controller:topic", dt7948.p({
          pluginId: "locations-plugin",
          setEditingTopicOnModel() {
            this.set("model.editingTopic", this.get("editingTopic"));
          }
        }, [["method", "setEditingTopicOnModel", [(0, _decorators.observes)("editingTopic")]]]));
        api.modifyClass("component:edit-category-settings", dt7948.p({
          pluginId: "locations-plugin",
          availableViews(category) {
            let views = this._super(...arguments);
            if (category.get("custom_fields.location_enabled") && this.siteSettings.location_category_map_filter) {
              views.push({
                name: _I18n.default.t("filters.map.label"),
                value: "map"
              });
            }
            return views;
          }
        }, [["method", "availableViews", [(0, _decorators.default)("category")]]]));
        const mapRoutes = [`Map`, `MapCategory`, `MapCategoryNone`];
        mapRoutes.forEach(function (route) {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "locations-plugin",
            afterModel() {
              this.templateName = "discovery/map";
              return this._super(...arguments);
            }
          });
        });
        const categoryRoutes = ["category", "categoryNone"];
        categoryRoutes.forEach(function (route) {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "locations-plugin",
            afterModel(model, transition) {
              if (this.filter(model.category) === "map" && this.siteSettings.location_category_map_filter) {
                transition.abort();
                return this.replaceWith(`/c/${this.Category.slugFor(model.category)}/l/${this.filter(model.category)}`);
              } else {
                return this._super(...arguments);
              }
            }
          });
        });
      });
      _composer.default.serializeOnCreate("location");
      _composer.default.serializeToTopic("location", "topic.location");
      _navItem.default.reopenClass({
        buildList(category, args) {
          let items = this._super(category, args);

          // Don't show Site Level "/map"
          if (typeof category !== "undefined" && category && category.custom_fields.location_enabled && category.siteSettings.location_category_map_filter) {
            items.push(_navItem.default.fromText("map", args)); // Show category level "/map" instead
          }
          return items;
        }
      });
    }
  };
});